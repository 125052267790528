export const enum AiModelOptionEnum {
    HAIRCOLOUR = 100, // 发色
    HAIRSTYLE = 200, // 发型
    HAIRDECORATION = 300, // 装饰
    SCENE = 400 // 场景
}

export const enum AiWeddingDressEnum {
    DIRECTION = 200, // 方向
}

export const enum PaintTabEnum {
    NEW, // 快速生成
    MASTER // 专业生成
}

export const PaintTabText: Record<number, string> = {
    [PaintTabEnum.NEW]: '快速生成', // 快速生成
    [PaintTabEnum.MASTER]: '专业生成(敬请期待~)' // 专业生成
}

// 类型
export const enum TypeEnum {
    CONCEPT_DESIGN, // 概念设计
    AI_DRESS_UP, // AI 换装
    AI_FACE_CHANGE, // AI 换脸
    PHOTOREALISTIC // 图片写实
}


export const enum DreamWorkType {
    "sceneDesign" = "sceneDesign",
    "faceDesign" = "faceDesign",
    "model" = "model",
    "weddingDress"="weddingDress"

}

export const SpeedQueueOptions = {
    viewDetailURL: "/ai/detail",
    fetchSpeedURL: "/dreamWorks/speedDetail",
    fetchResultURL: "/dreamWorks/getDetail"
}

//#36d4c7
export const drawColor = "rgba(54,212,199,0.6)";
export const drawFill = "rgba(54,212,199,0.01)";
import HelpIcon from "@mui/icons-material/Help";
import { SectionProps } from "./interface";
import "./sectionBox.scss";
import classNames from "classnames";
// import GlobalDialog from "@/components/globalDialog/globalDialog/globalDialog";
// import ExplainBox from "@/components/explainBox/explainBox";
import { Tooltip } from "@mui/material";

export default function SectionBox(props: SectionProps) {
	const { title, showQuestion, renderRightInfo, required, answers } = props;

	// const handleShowQuestion = () => {
	// 	GlobalDialog.show({
	// 		open: true,
	// 		PaperComponent: ExplainBox as any,
	// 		PaperProps: {
	// 			textContent: answers,
	// 		} as any,
	// 	});
	// };

	return (
		<div className="section-box">
			<div className="section-box-title">
				<div
					className={classNames("title-wrapper", {
						required: required,
					})}
				>
					{title && <h4 className="title-main">{title}</h4>}

					{showQuestion && (
						<Tooltip
							title={
								<div
									dangerouslySetInnerHTML={{
										__html: answers!,
									}}
								/>
							}
							placement="right"
						>
							<HelpIcon
								className="question"
							/>
						</Tooltip>
					)}
				</div>
				{renderRightInfo && (
					<div className="title-right">{renderRightInfo}</div>
				)}
			</div>
			<div className="section-box-content">{props.children}</div>
		</div>
	);
}

// 反馈类型
export const enum feedbackEnum {
    SUGGEST = 1,
    COMPLAINTS = 2,
    BUG = 3,
    OTHER = 99
}

export const feedbackTypeOptions  = [
    {
        name: '建议',
        value: feedbackEnum.SUGGEST
    },
    {
        name: '投诉',
        value: feedbackEnum.COMPLAINTS
    },
    {
        name: '故障',
        value: feedbackEnum.BUG
    },
    {
        name: '其他',
        value: feedbackEnum.OTHER
    }
]

import { FullScreenImage } from "@/components/fullScreenImage/fullScreenImage";
import { FullScreenImageProps } from "@/components/fullScreenImage/interface";
import GlobalModal from "@/components/globalDialog/globalModal/globalModal";
import GlobalSnackbar from '@/components/globalDialog/globalSnackbar';
import copy from 'copy-to-clipboard';

// 预览大图
export const previewImage = (props: FullScreenImageProps) => {
    const currentModal = GlobalModal.show({
        children: <FullScreenImage {...props} onClose={() => {
            props.onClose?.();
            currentModal.close();
        }} />
    })
}

export const generateBlob = (url: string) => {
    const xhr = new XMLHttpRequest()
    xhr.open("GET", url, true);
    xhr.responseType = 'blob';

    return new Promise((resolve, reject) => {
        xhr.onload = () => {
            resolve(xhr.response)
        }

        xhr.onerror = (err) => {
            reject(err)
        }

        xhr.send();
    })
}

export const downSvg = (res: string, index?: number) => {
    let blob = new Blob([res], {
        type: 'image/svg+xml',
    })
    let url = URL.createObjectURL(blob)
    let create_a_dom = document.createElement('a')
    document.body.append(create_a_dom)
    create_a_dom.href = url
    create_a_dom.download = String(index || -1);
    create_a_dom.rel = 'noopener noreferrer';
    create_a_dom.click();
    document.body.removeChild(create_a_dom);
}


export const download = (blob: Blob, name?: string, type?: string) => {
    //会创建一个 DOMString，其中包含一个表示参数中给出的对象的URL。这个 URL 的生命周期和创建它的窗口中的 document 绑定。这个新的URL 对象表示指定的 File 对象或 Blob 对象。
    const url = window.URL.createObjectURL(blob)
    const aDom = document.createElement('a')
    aDom.href = url;
    aDom.download = `${name ?? 'download-file'}${type ? '.' + type : ''}`;
    aDom.target = '_blank';
    document.body.appendChild(aDom);
    aDom.click()
    document.body.removeChild(aDom);
}

// 下载
export const downloadImgByBlob = (url: string, name?: string) => {
    downloadFileByBlob(url, name, 'jpeg');
}

// 下载文件
export const downloadFileByBlob = (url: string, name?: string, type?: string) => {
    generateBlob(url).then((res: any) => {
        download(res, name, type)
    })
}



// 一件复制
export const copyToClipboard = (value: string) => {
    copy(value);
    GlobalSnackbar.show({
        icon: 'success',
        message: '复制成功'
    })
}

// 复制图片
export const copyImgToClipboard = (blob: Blob) => {
    const data = [
        new window.ClipboardItem({
            [blob!.type]: blob,
        } as Record<string, Blob>)
    ]

    navigator.clipboard.write(data).then(() => {
        GlobalSnackbar.show({
            icon: 'success',
            message: '复制成功'
        })
    })
}

// 阻止devtool
export const preventOpenDevtool = () => {
    if (process.env.NODE_ENV === 'production') {
        // @ts-ignore
        DisableDevtool?.({
            md5: '3568ed5cc8deffb0ea7f5ff039b414f6'
        });
    }
}

// 验证手机号码
export const validatePhone = (value?: string) => {
    const phonePattern =
        /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
    return phonePattern.test(value!)
}

export const allocateItems = (
    listData: Array<any>,
    columnCount: number,
    itemWidth: number,
    newSpacing: number
) => {
    const renderData: any = Array.from({
        length: columnCount || 1,
    }).map(() => ({
        height: 0,
        data: [],
    }));
    listData.forEach((item: any) => {
        const newRenderData = [...renderData];
        newRenderData.sort((pre, nex) => pre.height - nex.height);
        if (newRenderData[0] && item.width) {
            newRenderData[0]?.data && newRenderData[0].data.push(item);
            newRenderData[0].height +=
                (item.height / item.width) * (itemWidth - newSpacing || 8) +
                newSpacing || 8;
        }
    });
    return renderData;

}



export const base64ToBlob = (data = '', sliceSize = 512) => {
    return new Promise((resolve, reject) => {
        const dataBase64 = data.replace(/^data:image(png|jpg|webp);base64,/, '');
        const arr:any = data.split(',');//分割为数组，分割到第一个逗号
        let mime = arr[0].match(/:(.*?);/)[1];//获取分割后的base64前缀中的类型
        let byteCharacters = window.atob(arr[1]);
        let byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);
            let byteNumbers = [];
            for (let i = 0; i < slice.length; i++) {
                byteNumbers.push(slice.charCodeAt(i));
            }
            // 8 位无符号整数值的类型化数组。内容将初始化为 0。
            // 如果无法分配请求数目的字节，则将引发异常。
            byteArrays.push(new Uint8Array(byteNumbers));
        }
        let result = new Blob(byteArrays, {
            type: mime
        })
        resolve(result);
    })
}

export const getCookie = (key: string) => {
    if (document.cookie) {
        const cookieInfo = document.cookie;
        let arr = cookieInfo.replace(/;\s/g, ';').split(";");
        for (let i = 0; i < arr.length; i++) {
            let item = arr[i].split("=");
            if (item[0] === key) {
                return item[1];
            }
        }
        return null;
    }
    return null;
}

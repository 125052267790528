import { getMemberStatus } from "@/api/dreamwork";
import { getUserInfo } from "@/api/user";
import { userActions } from "@/app/store/slice/user";
import GlobalDialog from "@/components/globalDialog/globalDialog/globalDialog";
import { LoginProps } from "@/components/loginBox/interface";
import Login from "@/components/loginBox/loginBox";
import { USER_TOKEN } from "@/constants/localStorage";
import { getCookie } from '@/utils';
import React from "react";

// 调用登录弹窗
let isOpenLogin = false;
export const openLoginModal = (props: LoginProps = {}) => {
	if (isOpenLogin) return Promise.resolve(true);
	return new Promise((resolve, reject) => {
		isOpenLogin = true;
		const dialog = GlobalDialog.show({
			children: (
				<Login
					{...props}
					onSuccess={() => {
						isOpenLogin = false;
						dialog.close();
						props.onSuccess?.();
						resolve(null);
					}}
				/>
			),
			onClose: () => {
				isOpenLogin = false;
				// reject(false);
			},
		});
		return dialog;
	});
};

// 获取用户信息
export function setUserInfoService() {
	return getUserInfo().then((res) => {
		if (res) {
			const {
				nickname,
				phone,
				integral = 0,//永久积分
				tempIntegral = 0,//临时积分
				headImg,
				balance,
				id,
				freeTime
			} = res;
			React.$store.dispatch(
				userActions.setUserInfo({
					phone,
					paintTotalNum: (integral + tempIntegral),
					headImg,
					nickname,
					balance,
					freeTime,
					integral,
					tempIntegral,
					isFetch: true,
					userId: id
				})
			);
		}
	}).catch(err=>{
		console.log(err);		
	});
}

export function setUserCreateMemberInfo(appType: number) {
	return getMemberStatus(appType).then((res) => {
		if (res) {
			React.$store.dispatch(
				userActions.setUserCreateMemberType({ [appType]: res })
			);
		}
	});
}

// 设置邀请人的ID
export function setInviterId(inviterId: number) {
	React.$store.dispatch(userActions.setInviterId(inviterId));
}

// 判断是否登录了
export function isLogin() {
	return !!React.$store.getState().user.phone;
}

export function pageInitService() {
	return setUserInfoService();
}

export function pageInitIfUserUnLoginService() {
	const cookie = getCookie(USER_TOKEN);
	if (cookie) {
		localStorage.setItem(USER_TOKEN, cookie);
	}
	
	const fly_user_token = localStorage.getItem(USER_TOKEN);
	if (fly_user_token) {
		return pageInitService();
	}

	return Promise.resolve();
}
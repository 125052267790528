import { loginPassword, loginVerifyCode, sendVerifyCode, wxUserBindPhone } from "@/api/user";
import { LoginStatus, userActions } from "@/app/store/slice/user";
import GlobalDialog from "@/components/globalDialog/globalDialog/globalDialog";
import GlobalSnackbar from "@/components/globalDialog/globalSnackbar";
import { LoginProps } from "@/components/loginBox/interface";
import { PROTOCOL } from "@/constants/config";
import { USER_TOKEN } from "@/constants/localStorage";
import useCountDown from "@/hooks/useCountDown";
import { setUserInfoService } from "@/service/user";
import wxlogin from "@/utils/wxlogin";
import { Box, Button, Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./loginBox.module.scss";

interface FormDataProps {
	phone?: string;
	verifyCode?: string;
	password?: string;
}

interface ErrorConfigProps {
	phone?: boolean;
	verifyCode?: boolean;
	password?: boolean;
}

export default function LoginBox(props: LoginProps) {
	const [seconds, setSeconds] = useCountDown(0);
	const [type, setType] = useState("phone");
	const [errorConfig, setErrorConfig] = useState<ErrorConfigProps>({});
	const [formData, setFormData] = useState<FormDataProps>({});
	const [agreePolicy, setAgreePolicy] = useState(false);
	const isSupplementMode = React.$store.getState().user.loginStatus === LoginStatus.supplement_phone;

	const { user: { inviterId } } = React.$store.getState()

	const handleVerifyCode = (event: React.MouseEvent<HTMLSpanElement>) => {
		event.stopPropagation()
		if (handleValidateField("phone", formData.phone)) {
			sendVerifyCode(formData.phone).then(() => {
				setSeconds(60);
			});
		}
	};

	const handleChangeType = () => {
		setType(type === "phone" ? "password" : "phone");
	};

	const handleChangeField = (field: string, value?: string) => {
		setFormData({ ...formData, [field]: value });
	};

	const handleValidateField = (field: string, value?: string): boolean => {
		const phonePattern =
			/^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
		if (field === "phone") {
			setErrorConfig({ phone: !phonePattern.test(value!) });
			return phonePattern.test(value!);
		}

		if (["verifyCode", "password"].includes(field)) {
			setErrorConfig({ verifyCode: !value, password: !value });
			return !!value;
		}

		return true;
	};

	const handleAgreePolicy = () => {
		setAgreePolicy(prevState => !prevState)
	}

	const checkAgreePolicy = () => {
		if (agreePolicy) {
			return true
		} else {
			GlobalSnackbar.show({
				icon: 'warning',
				message: '请阅读并勾选协议内容'
			})
			return false
		}
	}

	const handleLogin = () => {
		if (
			type === "phone" &&
			handleValidateField("phone", formData.phone) &&
			handleValidateField("verifyCode", formData.verifyCode) &&
			checkAgreePolicy()
		) {
			const commonParams = {
				inviterId,
				phone: formData.phone!,
				code: formData.verifyCode!,
			}
			// 根据登录状态区分绑定还是注册登录
			const request = isSupplementMode ?
				() => wxUserBindPhone({ ...commonParams, unionId: React.$store.getState().user.unionId }) :
				() => loginVerifyCode(commonParams);
			request().then((data) => {
				if (data) {
					React.$store.dispatch(userActions.setUserLoginStatus(LoginStatus.login));
					localStorage.setItem(USER_TOKEN, data);
					setUserInfoService().then(() => {
						props.onSuccess?.();
					}).then(res => {
					});
				}
			}).catch(() => { });
		}

		if (
			type === "password" &&
			handleValidateField("phone", formData.phone) &&
			handleValidateField("password", formData.password) &&
			checkAgreePolicy()
		) {
			loginPassword({
				inviterId,
				phone: formData.phone!,
				pass: formData.password!,
			}).then((data) => {
				localStorage.setItem(USER_TOKEN, data);
				setUserInfoService().then(() => {
					props.onSuccess?.();
				});
			});
		}
	};

	const handleWxLogin = useCallback(() => {
		GlobalDialog.show({
			children: <Box sx={{ padding: '20px', backgroundColor: '#e9e9e9' }}>
				<div id='wx_login_container'></div>
			</Box>
		})
		setTimeout(() => {
			wxlogin({
				id: 'wx_login_container',
			})
		}, 200)
	}, [])

	useEffect(() => {
		if (isSupplementMode) {
			GlobalSnackbar.show({
				icon: 'info',
				message: '请补充手机号'
			})
		}
	}, [isSupplementMode])

	return (
		<div className={styles.contentWrapper}>
			<div className={styles.loginTitle} />
			<div className={styles.loginContent}>
				<TextField
					fullWidth
					error={errorConfig.phone as boolean}
					id="phone"
					name="phone"
					label="请输入手机号码"
					variant="outlined"
					margin="normal"
					helperText={errorConfig.phone ? "请输入正确的手机号码" : ""}
					onChange={(e) => handleChangeField("phone", e.target.value)}
				/>

				{type === "phone" ? (
					<div className={styles.verifyCodeWrapper}>
						<TextField
							fullWidth
							error={errorConfig.verifyCode as boolean}
							id="verifyCode"
							name="verifyCode"
							label="请输入验证码"
							variant="outlined"
							margin="normal"
							helperText={
								errorConfig.verifyCode ? "请输入验证码" : ""
							}
							onChange={(e) =>
								handleChangeField("verifyCode", e.target.value)
							}
						/>

						<span
							className={styles.code}
							onClick={handleVerifyCode}
						>
							{seconds > 0 ? `剩余${seconds}S` : "获取验证码"}
						</span>
					</div>
				) : (
					<TextField
						fullWidth
						error={errorConfig.password}
						id="password"
						type="password"
						name="password"
						label="请输入密码"
						variant="outlined"
						margin="normal"
						helperText={errorConfig.password ? "请输入密码" : ""}
						onChange={(e) =>
							handleChangeField("password", e.target.value)
						}
					/>
				)}
				<Button
					fullWidth
					variant="contained"
					className={styles.btn}
					onClick={handleLogin}
				>
					{
						isSupplementMode ? '绑定手机号' : '登录'
					}
				</Button>

				<div className={styles.tips}>
					{
						isSupplementMode ?
							<span style={{ color: "color: rgb(255, 148, 72)", background: "rgb(255, 247, 242)", padding: "5px" }}>
								应《中华人民共和国网络安全法》要求，使用互联网服务需要进行账号实名认证
							</span> :
							'未注册手机验证后自动登录，注册即代表同意'
					}
				</div>
				<div className={styles.protocol}>
					<FormGroup>
						<FormControlLabel control={<Checkbox checked={agreePolicy} onChange={handleAgreePolicy} sx={{
							color: '#9a9a9a',
							'&.Mui-checked': {
								color: '#36d4c7',
							},
						}} />} label={
							<>
								<span>我已阅读并同意</span>
								<a
									href={PROTOCOL.user}
									target="_blank"
									rel="noreferrer"
								>
									《飞链云用户服务协议》
								</a>
								<span>和</span>
								<a
									href={PROTOCOL.privacyPolicy}
									rel="noreferrer"
								>
									《隐私政策》
								</a>
							</>
						} />
					</FormGroup>
				</div>
				{
					!isSupplementMode ? <div className={styles.extraLoginType}>
						<div className={styles.title}>其他登录方式</div>
						<div className={styles.types}>
							<span onClick={handleChangeType}>
								{type === "phone" ? "账号密码登录" : "手机号码登录"}
							</span>
							<span onClick={handleWxLogin}>微信扫码登录</span>
						</div>
					</div> : null
				}
			</div>
		</div>
	);
}
import { ReactElement } from 'react'
import { render, unmount as reactUnmount } from './render'

export function renderToBody(element: ReactElement, id?: string) {
    const container = document.createElement('div');
    if (id) {
        document.getElementById(id)?.appendChild(container)
    } else {
        document.body.appendChild(container)
    }
    function unmount() {
        const unmountResult = reactUnmount(container)
        if (unmountResult && container.parentNode) {
            container.parentNode.removeChild(container)
        }
    }
    render(element, container)
    return unmount
}
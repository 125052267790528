import * as utils from './index';
import React from "react";
import {currentNavigate, injectNavigate} from "@/hooks/useWithRouter";

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
    React.$utils = utils
    React.$currentNavigate = currentNavigate
    React.$injectCurrentNavigate = injectNavigate
}


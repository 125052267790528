
//@ts-nocheck
import QRCodeBox from '@/components/common/QRCodeBox';
import { HtmlTooltip } from '@/components/common/tooltip';
import GlobalDialog from "@/components/globalDialog/globalDialog/globalDialog";
import { QrCODE } from '@/constants/config/index';
import ChatIcon from '@mui/icons-material/Chat';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Face4Icon from '@mui/icons-material/Face4';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import FeedbackModal from './components/FeedbakcModal';
import styles from "./index.module.scss";

export default function GuideIcon() {
	const [open, setOpen] = useState<boolean>(false);

	const contact = () => {
		const dialog = GlobalDialog.show({
			children: (
				<QRCodeBox
					src={QrCODE.HelpQRCode}
					title="联系客服"
					subTitle="微信扫我"
					onClick={() => {
						dialog.close();
					}}
				/>
			)
		})

	}

	const help = [
		{
			"label": "联系我们",
			icon: <Face4Icon />,
			onClick: contact
		}, {
			"label": "反馈",
			icon: <ChatIcon />,
			onClick: () => setOpen(true)
		}
	];

	return (<><div className={styles.guide}>
		<HtmlTooltip
			placement='top'
			title={<Paper sx={{ width: 320, maxWidth: '100%' }}>
				{
					help.map(item => {
						return <MenuItem key={item.label} onClick={item.onClick}>
							<ListItemIcon >
								{item.icon}
							</ListItemIcon>
							<ListItemText>{item.label}</ListItemText>
						</MenuItem>
					})
				}
			</Paper>}>
			<ContactSupportIcon sx={{ fontSize: "60px", color: "#36d4c7" }} />
		</HtmlTooltip>
	</div>
		<FeedbackModal open={open} onClose={() => setOpen(false)} />
	</>
	);
}

import {
	Button,
	Paper,
	Stack,
	Typography
} from "@mui/material";

const QrCodeBox = ({
	src,
	title,
	subTitle,
	onClick,
}: {
	title: string;
	src: string;
	subTitle?: string;
	onClick?: () => void;
}) => {
	return (
		<Paper
			elevation={3}
			sx={{
				width: "300px",
				height: "300px",
				boxSizing: "border-box",
				overflow: "hidden",
			}}
		>
			<Stack justifyContent="center" alignItems="center" sx={{ p: 2 }}>
				<Typography variant="h6" sx={{ pt: 1 }} gutterBottom>
					{title}
				</Typography>
				{subTitle && <Typography gutterBottom>{subTitle}</Typography>}
				<img src={src} width="140px" height="140px" alt="二维码" />
				<Button
					onClick={onClick}
					fullWidth
					variant="contained"
					sx={{ mt: 2 }}
				>
					知道了
				</Button>
			</Stack>
		</Paper>
	);
};

export default  QrCodeBox;
import FooterBar from "@/components/footerBar/footerBar";
import { LayoutProps } from "@/components/layout/interface";
import NavBar from "@/components/navBar/navBar";
import { Suspense } from "react";
import { useLocation } from "react-router-dom";
import HelpIcon from "../guideIcon";
import ScrollTop from "../scrollTop";
import styles from "./layout.module.scss";

export default function Layout(props: LayoutProps) {
	const location = useLocation();

	return (
		<div className={styles.layout} id="back-to-top-anchor">
			<div className={styles.header}>
				<NavBar />
			</div>
			<div className={styles.body}>
				<Suspense fallback={<div />}>{props.children}</Suspense>
			</div>
			{!location.pathname.includes('ai') && (
				<div className={styles.footer}>
					<FooterBar />
				</div>
			)}
			<ScrollTop />
			<HelpIcon />
		</div>
	);
}

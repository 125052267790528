import CanvasRouter from '@/router/routes/canvasRouter';
import DreamWorksRouter from '@/router/routes/dreamWorksRouter';
import PersonalCenterRouter from '@/router/routes/personalRouter';
import { lazy } from "react";

const Page404 = lazy(() => import("@/pages/404/404"));

const routes =  [
    DreamWorksRouter,
    PersonalCenterRouter,
    CanvasRouter,
    {
        path: '*',
        element: <Page404 />
    }
]

export default routes

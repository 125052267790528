import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

export default function KeepAlive(props: any) {
    const { include, keys, children } = props
    const { pathname } = useLocation()
    const componentList = useRef(new Map())
    const activeKey = useRef<string>('')
    const [, updateState] = useState<unknown>();
    const forceUpdate = useCallback(() => updateState({}), []);
    const cacheKey = useMemo(() => pathname + "__" + keys[pathname], [pathname, keys])

    useEffect(() => {
        componentList.current.forEach(function(value, key) {
            const [_pathname, _cacheKey] = key.split("__")
            if (!include.includes(_pathname) || ((_pathname === pathname) && _cacheKey === keys[_pathname])) {
                componentList.current.delete(key)
            }
        })

        activeKey.current = cacheKey
        if (!componentList.current.has(activeKey.current)) {
            componentList.current.set(activeKey.current, children)
        }
        forceUpdate()
    }, [cacheKey, include, forceUpdate])

    return <>
        {
            Array.from(componentList.current).map(([key, component]) => {
                return key === activeKey.current ?
                    <div
                        key={key}
                        className={
                            `keep-container${include.includes(key.split("__")[0]) ? " keep-alive-fade": ""}`
                        }>
                        { component }
                    </div> :
                    <div
                        key={key}
                        className='keep-container__keep-alive'
                        style={{ display: 'none' }}>
                        { component }
                    </div>
            })
        }
    </>
}
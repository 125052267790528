import { DreamWorkType } from "@/constants/enums/dreamwork";

export interface OptionItem {
	scene?: string;
	title: string;
	description:string;
	case: string;
	caseLg: string;
	imgList?:any;
	link:string;
}




export const DreamWorkOptions: Array<OptionItem> = [
	{
		scene: DreamWorkType.model,
		title: "AI模特",
		description:"飞链云AI采用先进的视觉和图形相关神经算法，无需真人模特即可实现与真人拍摄效果相当甚至更优的展示效果。用户可以通过多种选项自由选择模特、场景、装饰、光影、身高和体型等，将衣服“穿”到所选模特身上。在这里，模特、场景、装饰、光影等都可以进行选择和扩展。有了这项技术，您不再需要经历传统的服装拍摄过程，就可以直观地查看服装的上身效果图片。",
		case: "https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/dream-works/mote.jpeg",
		caseLg: "https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/dream-works/mote2.jpeg",
		link:"/ai/model"
	},
	{
		scene: DreamWorkType.weddingDress,
		title: "AI婚纱数字化",
		description:"飞链云AI绘画技术是婚纱设计师的得力助手，能够在设计过程中为设计师提供更多的创意灵感。通过飞链云AI系统，设计师可以快速生成全球独一无二的婚纱设计图片，创作出更多具有创新性和个性化的婚纱设计方案。此外，系统还支持上传客户的人像，让设计师可以快速获取适合客户造型的婚纱设计，提供更加个性化的服务。让飞链云AI帮助您打破传统的设计束缚，创造出更惊艳、更符合客户需求的婚纱设计！",
		case: "https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/dream-works/hunsha.jpeg",
		caseLg: "https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/dream-works/hunsha2.jpeg",
		link:"/ai/weddingDress"
	},
	{
		scene: DreamWorkType.faceDesign,
		title: "AI脸部重塑器",
		description:"使用飞链云AI脸部重塑器，一键操作即可实现脸部重塑，您可以应用各种各样的脸型选项，不管是哪个国家的脸型，喜欢哪种风格都可以一键制作。飞链云自行合成数字脸，飞链云将商用权授予给您免费使用，无需担心商用场景风险。如您对脸型有特殊要求，可联系我们，提供您拥有肖像权的脸型照片，我们定制后供您独自使用。",
		case: "https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/dream-works/huanlian.jpeg",
		caseLg: "https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/dream-works/huanlian2.jpeg",
		link:"/ai/faceDesign"
	},
	{
		scene: DreamWorkType.sceneDesign,
		title: "AI人像换景器",
		description:"使用飞链云AI人像换景器 ，可实现人物不变，人物照片的背景随意变换。您可以应用各种各样的背景选项，不管是森林、室内、室外、山崖、海边、天空、宇宙、火星、城堡、唐朝、古代、纯色等任何背景，您都可以一键制作，每次生成的背景均为全球独一无二的背景，无需担心任何侵权风险。如您对某些背景有特殊偏好，可联系我们进行免费定制。",
		case: "https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/dream-works/huanjing.jpeg",
		caseLg: "https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/dream-works/huanjing2.jpeg",
		link:"/ai/sceneDesign"
	},
]; 
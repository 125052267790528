import {
	FeedbackReq,
	IPersonalInfo,
	PaintRequestSpeedDetail,
} from "@/api/feilianyun/type";
import { instance } from "@/api/instance";


//绘制结果
export function paint_result(url:string,opts: any) {
    if(url){
        return  instance<PaintRequestSpeedDetail>({
            method: 'GET',
            url: url,
            params: opts
        });
    }
    return Promise.reject(false);
}

// 绘画进度详情
export function pc_paint_speed_detail(opts: any) {
	return instance<PaintRequestSpeedDetail>({
		method: "GET",
		url: "/speed/detail",
		params: opts,
	});
}

//绘制进度
export function paint_speed_detail(url:string,opts: any) {
    if(url){
        return  instance<PaintRequestSpeedDetail>({
            method: 'GET',
            url: url,
            params: opts
        });
    }
    return Promise.reject(false);
}

// 获取分享海报
export function getSharePoster() {
	return instance<string>({
		method: "GET",
		url: "/share/poster",
	});
}

// 用户反馈
export function addFeedback(data: FeedbackReq) {
	return instance<boolean>({
		method: "POST",
		url: "/feedback/add",
		data,
	});
}

// 获取个人主页信息
export function pc_personalInfo(userId?: number | null) {
	return instance<IPersonalInfo>({
		method: "GET",
		url: "/user/data/info",
		params: { userId },
	});
}

// 上传图片
export function pc_upload(opts: any) {
	return instance({
		method: "POST",
		url: "upload/img",
		data: opts,
	});
}

// 用户会员状态
export enum UserMemberStatus {
	noMember = 1,
	expire,
	member,
}

// 用户会员类型
// 目前会员用后端返回，已失效,仅用于表示是否是会员
export enum UserMemberType {
	normal,
	primary,
	medium,
	senior,
	supreme,
}

export enum MemberLevel {
	NORMAL = 0, // 普通用户
	INTERNSHIP = 5, // 实习
	BRONZE = 1, // 青铜
	SILVER = 6, // 白银
	GOLD = 2, // 黄金
	PLATINUM = 3, // 钻石
	BETA = 10, // 内测
}

// 支付途径
export enum PayType {
	WECHAT = 1,
	ALIPAY = 2,
	WALLET = 3,
}

// 支付
export const PAY_METHOD_CODE = {
	[PayType.WECHAT]: "wechat_pay_native",
	[PayType.ALIPAY]: "alipay_qrcode",
	[PayType.WALLET]: "wallet_pay",
};

export const PAY_PRODUCT_TYPE = {
	//产品类型 1ai次数 2会员
	FEI_XIN: 1,
	HUI_YUAN: 2,
};

// 支付方式
export enum PayMethod {
	alipay_qrcode = "alipay_qrcode",
	wechat_pay_native = "wechat_pay_native",
	wallet_pay = "wallet_pay",
}

// 通过支付途径获取支付方式
export const PayTypeToPayMethod = {
	[PayType.WECHAT]: PayMethod.wechat_pay_native,
	[PayType.ALIPAY]: PayMethod.alipay_qrcode,
	[PayType.WALLET]: PayMethod.wallet_pay,
};

export enum PayProductType {
	AITIMES = 1,
	MEMBER = 2,
}

// 支付结果
export enum PayStatus {
	WAITING = 1, // 待支付
	SUCCESS = 2, // 支付成功
	FAIL = 3, // 支付失败
}

export const enum CastType {
	times,
	money,
	all,
}

import { UserMemberStatus, UserMemberType } from "@/constants/enums/member";
import { permissionMap } from "@/constants/enums/permissions";
import { USER_TOKEN } from "@/constants/localStorage";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { isObject } from 'lodash-es';
export enum LoginStatus {
    unLogin,
    login,
    supplement_phone
}

export interface User {
    token: string; // 用户token
    name: string; // 用户名称
    balance: string; // 用户余额
    headImg: string; // 用户头像
    phone: string | null; // 手机号
    count: number; // 绘图次数
    longCount: number; // 永久绘图次数
    shortCount: number; // 临时绘图次数
    paintTotalNum: number; // 用户画板的总数
    memberType: UserMemberType; // 会员类型
    memberTypeName: string; // 会员类型名称
    memberStatus: UserMemberStatus; // 会员状态
    memberMetaInfo: {[key: string]: any} // 会员其他信息avatar
    userPermissions: {[key: number]: number} // 用户权限
    inviterId: number | null // 邀请人ID
    userId: number
    loginStatus: LoginStatus // 当前的登录状态
    unionId: string // 微信的唯一凭证
    freeTime: number // 用户免费创作倒计时
    aiCreateMemberMetaInfo:any//创作用户会员信息
    integral:number;//永久飞星
	tempIntegral:number;//临时飞星
    isFetch:boolean;//是否发送请求
}

const initialState: User = {
    token: '',
    name: '创作者',
    balance: '',
    headImg: 'https://osscdn.feilianyunnft.com/imgs/202211/8b4515d7be2f4e454f3916e70b09774d1cee5f29619f4dda6269a6c5c50b57bf.png',
    phone: null,
    count: 0,
    longCount: 0,
    shortCount: 0,
    paintTotalNum: 0,
    memberType: UserMemberType.normal,
    memberTypeName: '',
    memberStatus: UserMemberStatus.noMember,
    memberMetaInfo: {},
    userPermissions: {...permissionMap},
    inviterId: null,
    userId: -1,
    loginStatus: LoginStatus.unLogin,
    unionId: '',
    freeTime: 0,
    integral:0,
    tempIntegral:0,
    isFetch:false,
    aiCreateMemberMetaInfo:{

    }
};

export const slice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        setUserToken: (state, action) => {
            state.token = action.payload
        },
        setUserName: (state, action) => {
            state.name = action.payload
        },
        setUserId: (state, action) => {
            state.userId = action.payload
        },
        setUserAvatar: (state, action) => {
            state.headImg = action.payload
        },
        setUserPhone: (state, action) => {
            state.phone = action.payload
        },
        setUserCount: (state, action) => {
            state.count = action.payload
        },
        setUserLongCount: (state, action) => {
            state.longCount = action.payload
        },
        setUserShortCount: (state, action) => {
            state.shortCount = action.payload
        },
        setUserMemberType: (state, action) => {
            state.memberType = action.payload
        },
        setUserMemberStatus: (state, action) => {
            state.memberStatus = action.payload
        },
        setUserPaintTotalNumber: (state, action) => {
            state.paintTotalNum= action.payload
        },
        setUserMemberMetaInfo: (state, action) => {
            state.memberMetaInfo = action.payload
        },
        setUserCreateMemberType:(state, action)=>{
            state.aiCreateMemberMetaInfo = action.payload;
        },
        setInviterId: (state, action) => {
            state.inviterId = action.payload
        },
        setBalance: (state, action)=>{
            state.balance = action.payload
        },
        setUserLoginOut: () => {
            localStorage.removeItem(USER_TOKEN);
            return initialState;
        },
        setUserLoginStatus: (state, action: PayloadAction<LoginStatus>) => {
            state.loginStatus = action.payload;
        },
        setUserUnionId: (state, action) => {
            state.unionId = action.payload;
        },
        setFreeTime: (state, action) => {
            state.freeTime = action.payload;
        },
        setUserInfo:(state, action) => {
            const newValue:any=action.payload;
            if(isObject(newValue)){
                const newState=Object.assign(state,newValue);
               return newState;
            }
        }
    }
})

export const userActions = slice.actions;


export default slice.reducer;

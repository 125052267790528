import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Divider, TextField } from "@mui/material";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { styled } from "@mui/material/styles";
import { ChangeEvent, useCallback } from "react";
import { TextareaProps } from "./interface";
import styles from './textarea.module.scss';

const CustomTextarea = styled(TextField)(`
  & .${outlinedInputClasses.notchedOutline} {
    border-color: #575757;
  }
  &:hover .${outlinedInputClasses.notchedOutline} {
    border-color: #fff;
  }
`);

export default function Textarea(props: TextareaProps) {
    const {placeholder, limit, rows, onInput, value, onBlur, error } = props;

    const handleTextareaChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
        const text = e.target.value;
        if (limit) {
            if (text.length < limit) {
                onInput?.(text);
            }
        } else {
            onInput?.(text);
        }
    }, [onInput, limit])

    const handleTextareaBlur = (value: string) => {
        onBlur?.(value)
    }

    const handleClear = useCallback(() => {
        onInput?.('');
    }, [onInput])

    return <div className={styles.selfTextarea}>
        <CustomTextarea
            fullWidth
            multiline
            className={styles.textarea}
            error={error}
            value={value}
            rows={rows}
            placeholder={placeholder}
            color='primary'
            onChange={handleTextareaChange}
            onBlur={(event) => handleTextareaBlur(event.target.value)}
        />
        <div className={styles.footer}>
            {
                limit ? <div className={styles.countWrapper}>
                    <div>{value?.length || 0}</div>
                    <div className={styles.splitLine}>/</div>
                    <div>{limit}</div>
                    <Divider orientation="vertical" flexItem className={styles.countLine}/>
                    <div className={styles.clear} onClick={handleClear}>
                        <DeleteOutlineIcon className={styles.trashIcon}/>
                        <p>清空</p>
                    </div>
                </div> : null
            }
        </div>
    </div>
}

Textarea.defaultProps = {
    rows: 4
}
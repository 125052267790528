import { useAppSelector } from "@/app/hooks";
import { USER_TOKEN } from "@/constants/localStorage";
import routes from "@/router/routes";
import { openLoginModal } from "@/service/user";
import { PropsWithChildren, useEffect } from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";

export interface AuthRouterProps extends PropsWithChildren {
	title?: string;
}

export default function AuthRouter(props: AuthRouterProps) {
	const { children, title } = props;
	const navigate = useNavigate();
	const location = useLocation();
	const { isFetch,phone } = useAppSelector((state) => state.user);
	const matches = matchRoutes(routes, window.location);

	const index: any = matches?.findIndex(
		(item) => item.pathname === location.pathname
	);

	useEffect(() => {
		title && (document.title = title);

		// 页面切换的时候移动到顶部
		window.scrollTo({
			left: 0,
			top: 0,
		});
		// 没有登录的打开登录操作
		const route: any = index !== -1 && matches?.[index]?.route;
		if (route?.needAuth && !localStorage.getItem(USER_TOKEN) && isFetch) {
			 openLoginModal();
			return;
		}
		if (index !== -1 && phone) {
			if (location.pathname === "/login") {
				navigate("/", { replace: true });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname, phone]);

	return <div id="_first_router_out">{children}</div>;
}

import { instance } from "@/api/instance";
import { ListRes } from "../feilianyun/type";
import {
	AiModelDetail,
	CreateDreamWorkParams,
	DreamWorkOptions,
	ListItem,
	ListReq,
} from "./type";

// 获取AIModel配置参数
export function getAiModelOptions() {
	return instance<DreamWorkOptions>({
		method: "GET",
		url: "/aiModel/options",
	});
}
// 生成AI模特
export function createAiModel(opts: CreateDreamWorkParams) {
	return instance<string>({
		method: "POST",
		url: "/aiModel/submit",
		data: opts,
	});
}

// 生成婚纱
export function createAiWeddingDress(opts: CreateDreamWorkParams) {
	return instance<string>({
		method: "POST",
		url: "/aiWeddingDress/submit",
		data: opts,
	});
}

// 获取婚纱配置参数
export function getWeddingDressOptions() {
	return instance<DreamWorkOptions>({
		method: "GET",
		url: "/aiWeddingDress/options",
	});
}

//自动生成蒙版
export const autoExtractMask = (imageUrl: string) => {
	return instance({
		method: "GET",
		url: "/dreamWorks/autoExtractMask",
		params: {
			imageUrl,
		},
	});
};

// 查询梦工厂列表作品
export function getDreamWorkList(opts: ListReq) {
	return instance<ListRes<ListItem>>({
		method: "POST",
		url: "/dreamWorks/list",
		data: opts,
	});
}

// 删除梦工厂中的作品
export function deleteDreamWork(codes: string[]) {
	return instance({
		method: "POST",
		url: "/dreamWorks/delete",
		data: { codes },
	});
}

// 获取画作详情
export function getDreamWorkDetail(code: string) {
	return instance<AiModelDetail>({
		method: "GET",
		url: "/dreamWorks/getDetail",
		params: { code },
	});
}

/**
 * 获取会员状态
 * @param appType :1 ai模特、2数字脸生成、3人物背景生成，4婚纱数字化
 * @returns
 */
export function getMemberStatus(appType: number) {
	return instance({
		method: "GET",
		url: "/dreamWorks/getMember",
		params: { appType },
	});
}

// 邀请码校验
export function verifyActivationCode(code: string) {
	return instance<boolean>({
		method: "GET",
		url: "/activationCode/exchange",
		params: { code },
	});
}

//ai换脸菜单选项接口
export const getFaceOptions = () => {
	return instance({
		method: "GET",
		url: "/aiFaceSwap/options",
	});
};

/**
 * ai换脸 任务提交
 */
export const fetchFaceTask = (data: any) => {
	return instance({
		method: "POST",
		url: "/aiFaceSwap/submit",
		data: data,
	});
};

/**
 * 获取人像场景选项
 * @returns 
 */
export const getSceneOptions = () => {
	return instance({
		method: "GET",
		url: "/aiSceneChange/options"
	});
};

/**
 * 提交人像场景任务
 * @param data 
 * {
  "batchSize": 0,
  "croppedConfig": {
	"h": 0,
	"w": 0,
	"x": 0,
	"y": 0
  },
  "croppedImages": "",
  "feature": 0,
  "optionMenus": [
	{
	  "optionId": 0,
	  "optionMenuValue": 0
	}
  ],
  "originalImages": ""
}
 * @returns 
 */
export const fetchSceneTask = (data: any) => {
	return instance({
		method: "POST",
		url: "/aiSceneChange/submit",
		data: data,
	});
};


//自动分析图片
export const automaticAnalysis = (imageUrl: string) => {
	return instance({
		method: "GET",
		url: "/flyCv/automaticAnalysis",
		params: {
			imageUrl
		}
	});
};


//查询图片的打分
export const markQuery = (taskCode: string) => {
	return instance({
		method: "GET",
		url: "/feedback/feedback/image/mark/query",
		params: {
			taskCode
		}
	});
};


/**
 * 打分
 * data
 * @param data  {
 * feedback：string 其他反馈；
 * markValue：number <double> 打分的值
 * tags：打分的标签以英文的逗号(,)拼接
 * }
 * @returns 
 */
export const postRate = (data: any) => {
	return instance({
		method: "POST",
		url: "feedback/feedback/image/mark",
		data: data,
	});
};

/**
 * 
	batchSize：integer <int32>批次数量
	businessCode：string 业务编号 可传原图编号
	croppedConfig：object (CroppedConfig) 裁剪配置
	croppedImages：string 裁剪后的图片
	maskImages：string 蒙版内容
	modelCode：string 模型编号
	originalImages:string 原图
	prompt:string 关键词

 * 涂抹重绘
 * @param data 
 * @returns 
 */
export const partialRedrawing = (data: any) => {
	return instance({
		method: "POST",
		url: "partialRedrawing/submit",
		data: data,
	});
};

export function paintDetail(code: string) {
	return instance<any>({
		method: "GET",
		url: "/draw/detail",
		params: { code },
	});
}

//根据原图code查询重绘任务列表
export function partialRedraw(code: string) {
	return instance<any>({
		method: "GET",
		url: "/partialRedrawing/query",
		params: { code },
	});
}











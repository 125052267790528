
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton } from "@mui/material";

import { styled } from '@mui/material/styles';
import { ReactNode } from "react";

const CustomizedDialog = styled((props: any) => <Dialog {...props} />)(({ theme, ...rest }) => {
  return `
    width:100%;
    .MuiButton-text{
      color: ${theme.palette.primary.main};
    }
    .MuiDialogContent-root {
      padding-top: 0;
    }

    .MuiDialogContent-root {
      color: #999;
    }

    & .MuiDialog-paper {
      width: 100%;
      margin:20px;
      height:${rest.isAuto ? "auto" : "100%"};
      color: #fff
    }
  `
});

interface FlyModalProps extends Omit<DialogProps, 'onClose' | 'title'> {
  title?: string | ReactNode
  className?: string
  footer?: ReactNode
  onClose: () => void
  onOk?: () => void
  fullScreen?: boolean
  maxWidth?: any
  isAuto?: boolean;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const DialogTitleHOC = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2,zIndex:100 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function FlyModal(props: FlyModalProps) {
  const { className, open, onClose, onOk, footer, maxWidth = 'sm', isAuto = true } = props;

  return (
    <CustomizedDialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      isAuto={isAuto}
      aria-labelledby="customized-dialog-title"
      className={className}
    >

      <DialogTitleHOC
        id="customized-dialog-title"
        onClose={onClose}
      >
        {props.title}
      </DialogTitleHOC>

      <DialogContent>{props.children}</DialogContent>

      <DialogActions>
        {
          (footer === null || footer) ? footer :
            <>
              <Button onClick={onClose}>取消</Button>
              <Button onClick={onOk}>确认</Button>
            </>
        }
      </DialogActions>
    </CustomizedDialog>
  )
}
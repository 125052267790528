import * as API from "@/api";
import { LoginStatus, userActions } from "@/app/store/slice/user";
import { USER_TOKEN } from "@/constants/localStorage";
import React from "react";

export interface LoginProps {
    url?: string
    id: string
    appid?: string
}

export const appId = 'wx9dcf3e4836a63c00';

function delUrlParam(params: string | string[]){
    let obj = new window.URL(window.location.href);
    if (Array.isArray(params)) {
        params.forEach(param => {
            obj.searchParams.delete(param);
        })
    } else {
        obj.searchParams.delete(params);
    }
    return obj.href;
}

function addUrlParam(key: string, value: string){
    let obj = new window.URL(window.location.href);
    obj.searchParams.set(key, value);
    return obj.href;
}

export function delQueryWithoutRefresh(keyName: string | string[], replace?: boolean) {
    const url = delUrlParam(keyName);
    if (replace) {
        window.history.replaceState({}, '0', url)
    } else {
        window.history.pushState({}, '0', url)
    }
}

export function addQueryWithoutRefresh(keyName: string, value: string, replace?: boolean) {
    const url = addUrlParam(keyName, value);
    if (replace) {
        window.history.replaceState({}, '0', url)
    } else {
        window.history.pushState({}, '0', url)
    }
}

export function consumeWxLoginCode() {
    let {searchParams} = new window.URL(window.location.href);
    const wxCode = searchParams.get('code');
    const wxStatus = searchParams.get('state');
    if (wxCode && wxStatus) {
        return API.user.wxQrLogin({
            code: wxCode,
            state: wxStatus,
        }).then(res => {
            if (res.unionId) {
                // 设置uuid供后面绑定使用
                React.$store.dispatch(userActions.setUserUnionId(res.unionId));
            }
            if (!res.bindPhone) {
                // 没有绑定手机号需要标识登录手动绑定
                React.$store.dispatch(userActions.setUserLoginStatus(LoginStatus.supplement_phone));
            } else {
                localStorage.setItem(USER_TOKEN, res.token!);
                delQueryWithoutRefresh(['code', 'state'], true)
            }
        })
    } else {
        return Promise.resolve()
    }
}

export default function({url, id, appid}: LoginProps) {
    const _wxLogin = window.WxLogin;
    const redirect_uri = encodeURIComponent(url || window.location.href);
    if (_wxLogin) {
        new _wxLogin({
            self_redirect: false,
            id: id,
            appid: appid || appId,
            scope: "snsapi_login",
            redirect_uri,
            state: "STATE",
            style: "black",
            href: ""
        });
    }
}
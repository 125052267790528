import Root from "@/pages/root/root";
import { lazy } from "react";

const DreamWorks = lazy(() => import("@/pages/dreamWorks/entry"))
const AiModel = lazy(() => import("@/pages/dreamWorks/aiModel"))
const AiWeddingDress = lazy(() => import("@/pages/dreamWorks/aiWeddingDress"))
const ArchitecturalDesign = lazy(() => import("@/pages/dreamWorks/archDesign"))
const FaceDesign = lazy(() => import("@/pages/dreamWorks/faceDesign"))
const SceneDesign = lazy(() => import("@/pages/dreamWorks/sceneDesign"))
const DreamWorksDetail= lazy(() => import("@/pages/dreamWorks/detail"));


const DreamWorksRouter = {
    path: "/ai",
    element: <Root />,
    children: [
        {
            path: '/ai',
            element: <DreamWorks />,
            title:"AI梦工厂-飞链云版图"
        },
        {
            path: '/ai/archDesign',
            element: <ArchitecturalDesign />,
            title:"AI建筑-飞链云版图"
        },
        {
            path: '/ai/model',
            element: <AiModel />,
            title:"AI模特-飞链云版图",
            needAuth: true
        },
        {
            path: '/ai/weddingDress',
            element: <AiWeddingDress />,
            title:"AI婚纱数字化-飞链云版图",
            needAuth: true
        },
        {
            path: '/ai/faceDesign',
            element: <FaceDesign />,
            title:"AI脸部重塑器-飞链云版图",
            needAuth: true
        },
        {
            path: '/ai/sceneDesign',
            element: <SceneDesign />,
            title:"AI人像换景器-飞链云版图",
            needAuth: true
        },
        {
            path: '/ai/detail/:id',
            element: <DreamWorksDetail />,
            title:"AI梦工厂详情-飞链云版图"
        },

    ],
}

export default DreamWorksRouter;
/* eslint-disable react-hooks/exhaustive-deps */
//@ts-nocheck
import { DreamWorkOptions } from "@/constants/config/dreamwork";
import { openLoginModal } from "@/service/user";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from "@mui/icons-material/Menu";
import {
	Box,
	Button,
	Container,
	Divider,
	Drawer,
	Grid,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Stack
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useCallback, useMemo, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { HtmlTooltip } from "../common/tooltip";
import styles from "./navBar.module.scss";

import { useAppSelector } from "@/app/hooks";
import UserCenterPopover from "@/components/userCenterPopover";
import { COMMON_URL } from "@/constants/config";

const paintUrl = process.env.REACT_APP_PAINT_HOST


export default function NavBar() {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
	const navigate = useNavigate();

	const [navSelectedIndex, setSelectedIndex] = useState(-1);

	const { phone, name, headImg, paintTotalNum } = useAppSelector(
		(state) => state.user
	);

	const [drawOpen, setDrawOpen] = useState(false);

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);

	const linkGroup = useMemo(() => [
		{
			name: "AI梦工厂",
			href: "/ai",
			hasMore: true
		},
		{
			name: "AI创作",
			href: `${(paintUrl || 'http://localhost:3000')}/ai/paint`,
			newTab: true,
			hasMore: false
		},
		{
			name: "个人中心",
			href: "/personalCenter",
			hideInHeader: !phone,
			hasMore: false
		},
	], [phone]);

	const handleLinkHome = () => {
		navigate("/ai");
	};

	const handleLogin = () => {
		openLoginModal();
	};

	const handleCloseDraw = useCallback(() => {
		setDrawOpen(false);
	}, []);

	const handleOpenDraw = useCallback(() => {
		setDrawOpen(true);
	}, []);

	const handleOpenUserPopover = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const renderMenu = useMemo(() => {
		return (
			<Grid item xs className={styles.menuBox}>
				<MenuIcon onClick={handleOpenDraw} />
			</Grid>
		);
	}, [handleOpenDraw]);

	const renderLoginUser = useMemo(() => {
		return phone ? (
			<>
				<div
					className={styles.avatarAndName}
					onClick={handleOpenUserPopover}
				>
					{headImg ? (
						<img
							src={headImg}
							alt="avatar"
							className={styles.avatar}
						/>
					) : (
						<AccountCircleIcon sx={{ fontSize: "28px" }} />
					)}
				</div>
				<div
					className={styles.aiPaintingTimes}
				>
					<img src={COMMON_URL.flyIcon} alt="flyIcon" />
					{paintTotalNum}
				</div>
			</>
		) : (
			<Button onClick={handleLogin}>登录/注册</Button>
		);
	}, [phone, name, paintTotalNum, handleLogin, handleOpenUserPopover]);

	const handleListItemClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		index: number,
		link: string
	) => {
		event.stopPropagation();
		setSelectedIndex(index);
		console.log("link", link);
		navigate(link, { replace: true });
	};


	return (
		<div className={styles.navBar}>
			<Container maxWidth="xl">
				<Grid container spacing={0}>
					<div
						className={styles.iconWrapper}
						onClick={handleLinkHome}
					>
						<div className={styles.iconWrapper} style={{ paddingRight: "20px" }}>
							<img
								className={styles.icon}
								src={COMMON_URL.logo}
								alt="飞链云"
							/>
							<div className={styles.iconText}>
								<div>飞链云版图</div>
								<div className={styles.iconTextSpan}>
									FLY-AI
								</div>
							</div>
						</div>
					</div>

					{matchesSm ? (
						renderMenu
					) : (
						<>
							<Divider orientation="vertical" flexItem />
							<Grid
								item
								xl={8}
								className={styles.linkWrapper}
							>
								{linkGroup.filter(item => !item.hideInHeader).map((link) => {
									return (
										<NavLink
											target={link.newTab ? '_blank' : '_self'}
											to={link.href!}
											className={({ isActive }) =>
												isActive
													? styles.linkActive
													: styles.link
											}
											key={link.href}
										>

											{link.hasMore ? <HtmlTooltip title={<List>{
												DreamWorkOptions.map((subItem, index) => (
													<ListItem key={subItem.scene} disablePadding>
														<ListItemButton selected={navSelectedIndex === index} onClick={(event) => handleListItemClick(event, index, subItem.link)}><span style={{color:"#fff"}}>{subItem.title}</span></ListItemButton>
													</ListItem>))
											}</List>}>
												<Stack direction="row" alignItems="center">{link.name}<ChevronRightIcon /></Stack>
											</HtmlTooltip> : <>{link.name}</>}
										</NavLink>
									);
								})}
							</Grid>
							<Grid sm md item className={styles.loginWrapper}>
								{renderLoginUser}
							</Grid>
						</>
					)}
				</Grid>
			</Container>
			<Drawer open={drawOpen} onClose={handleCloseDraw}>
				<List>
					<ListItem disablePadding>
						<ListItemText>
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									width="40px"
									height="40px"
									src={COMMON_URL.logo1}
									alt="飞链云"
									style={{ objectFit: 'contain' }}
								/>
								<div>FLY-AI</div>
							</Box>
						</ListItemText>
					</ListItem>
				</List>
				<Divider />
				<List>
					{linkGroup.filter(item => !item.hideInHeader).map((link) => {
						return (
							<ListItem disablePadding key={link.href}>
								<ListItemButton>
									<ListItemText>
										<Box sx={{ padding: "0 20px" }}>
											<NavLink
												target={link.newTab ? '_blank' : '_self'}
												to={link.href!}
												className={({ isActive }) =>
													isActive
														? styles.linkActive
														: styles.link
												}
											>
												{link.name}
											</NavLink>
										</Box>
									</ListItemText>
								</ListItemButton>
							</ListItem>
						);
					})}
				</List>
				<Divider />
				<List>
					<ListItem disablePadding>
						<ListItemText>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								{renderLoginUser}
							</Box>
						</ListItemText>
					</ListItem>
				</List>
			</Drawer>

			<UserCenterPopover
				anchorEl={anchorEl}
				open={!!anchorEl}
				onClose={() => {
					handleCloseDraw();
					setAnchorEl(null);
				}}
			/>
		</div>
	);
}

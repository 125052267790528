import { lazy } from "react";

const CanvasEdit = lazy(() => import("@/pages/canvasEdit"));


const CanvasRouter = {
    path: "/canvas_edit",
    element: <CanvasEdit />
}

export default CanvasRouter;
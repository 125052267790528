import { FullScreenImageProps } from "@/components/fullScreenImage/interface";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { useCallback, useState } from "react";
import styles from './fullScreenImage.module.scss';

export function FullScreenImage(props: FullScreenImageProps) {
    const { current, urls, originalImages } = props;
    const [imgUrl, setImgUrl] = useState(current);

    const handleClose = useCallback(() => {
        props.onClose?.()
    }, [])

    const handleChangeImg = useCallback((url: string) => {
        setImgUrl(url)
    }, [])

    return <div className={styles.fullScreenImage}>
        <div className={styles.images}>
            {originalImages && <div className={styles.mainImage}
                style={{
                    backgroundImage: `url(${originalImages})`,
                    borderRight: "1px solid rgba(255,255,255,0.3)",
                    backgroundPosition: "right"
                }}></div>}
            <div className={styles.mainImage} style={{ backgroundImage: `url(${imgUrl})`, backgroundPosition: "left" }}></div>
        </div>
        <div className={styles.otherImageBox}>
            <div className={styles.otherImageWrapper}>
                {
                    urls?.map(url => {
                        return <div className={styles.otherImage} style={{ backgroundImage: `url(${url})`, borderColor: imgUrl === url ? "#36d4c7" : "rgba(255,255,255,0.3)" }} onClick={handleChangeImg.bind(null, url)}></div>
                    })
                }
            </div>
        </div>
        <div className={styles.closeBox}>
            <CloseFullscreenIcon className={styles.closeBtn} onClick={handleClose} />
        </div>
    </div>
}
import { UserCenterProps } from "./interface";

import { logOut } from "@/api/user";
import { userActions } from "@/app/store/slice/user";
import { MemberLevel } from "@/constants/enums/member";
import { IosShare, Logout } from "@mui/icons-material";
import { Popover, Stack } from "@mui/material";
import { useRequest } from "ahooks";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ShareModal from "./components/ShareModal";
import styles from "./index.module.scss";

const memberTypeColor: Record<number, string> = {
	[MemberLevel.NORMAL]: "#e6e8ec",
	[MemberLevel.INTERNSHIP]: "#e6e8ec",
	[MemberLevel.BRONZE]: "#7d633f",
	[MemberLevel.SILVER]: "#777e90",
	[MemberLevel.GOLD]: "#b58854",
	[MemberLevel.PLATINUM]: "#7851ec",
};

export default function UserCenterPopover(props: UserCenterProps) {
	const navigate = useNavigate();
	const { onClose } = props;

	const {
		user: { memberMetaInfo, name },
	} = React.$store.getState();

	const [open, setOpen] = useState<boolean>(false);

	const { run: runLogOut } = useRequest(logOut, {
		manual: true,
		onSuccess: () => {
			React.$store.dispatch(userActions.setUserLoginOut());
			navigate("/");
		},
	});


	const handleClickWrapper = (fn: Function) => {
		onClose?.();
		fn();
	};


	const extraEntranceList = [
		{
			icon: <IosShare />,
			text: "邀请好友",
			onClick: () => setOpen(true),
		},
		// {
		// 	icon: <Settings />,
		// 	text: "密码设置",
		// 	onClick: () =>
		// 		handleClickWrapper(() => navigate("/personalCenter/settings")),
		// },
		{
			icon: <Logout />,
			text: "退出登录",
			onClick: () => handleClickWrapper(runLogOut),
		},
	];

	return (
		<Popover
			{...props}
			anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
		>
			<div className={styles.userCenterInner}>
				<Stack direction="row" alignItems="center" spacing={1}>
					<div className={styles.userName}>{name}</div>
					<div
						className={styles.memberName}
						style={{
							backgroundColor:
								memberTypeColor[memberMetaInfo.memberType],
							color:
								memberMetaInfo?.memberType ===
								MemberLevel.NORMAL
									? "#999"
									: "#fff",
						}}
					>
						{!!memberMetaInfo.iconImg && (
							<img src={memberMetaInfo.iconImg} alt="iconImg" />
						)}
						<span className={styles.name}>
							{memberMetaInfo.memberTypeName}
						</span>
					</div>
					{/* <IconButton
						onClick={() =>
							handleClickWrapper(() =>
								navigate("/personalCenter/settings")
							)
						}
					>
						<Edit />
					</IconButton> */}
				</Stack>

				<Stack
					className="extraEntranceWrapper"
					direction="row"
					alignItems="center"
					spacing={2}
				>
					{extraEntranceList.map((item) => (
						<div
							className={styles.extraEntranceItem}
							key={item.text}
							onClick={item.onClick}
						>
							{item.icon}
							<div className={styles.text}>{item.text}</div>
						</div>
					))}
				</Stack>
			</div>

			<ShareModal
				open={open}
				onClose={() => handleClickWrapper(() => setOpen(false))}
			/>
		</Popover>
	);
}

import * as API from "@/api";
import { UploadProps } from "@/components/upload/interface";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button } from "@mui/material";
import { ChangeEvent, useCallback, useRef, useState } from "react";
import GlobalSnackbar from "../globalDialog/globalSnackbar";
import { FileData } from "./interface";
import styles from "./upload.module.scss";

const unitMB = 1024 * 1024
export default function Upload(props: UploadProps) {
	const {
		accept,
		maxSize,
		style = {},
		fileList: propFileList = [],
		multiple = false,
	} = props;
	const [fileList, setFileList] = useState<FileData[]>(propFileList);
	const acceptType =
		accept?.map((item) => `.${item.toLowerCase()}`).join(", ") || "image/*";
	const inputRef = useRef<HTMLInputElement | any>();

	const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files ? Array.from(e.target.files) : null;
		const uploadFiles: FormData[] = [];
		files?.forEach((file) => {
			if (file.size <= maxSize!) {
				const fileData = new FormData();
				fileData.set("file", file);
				uploadFiles.push(fileData);
			} else {
				GlobalSnackbar.show({
					icon: "warning",
					message: `图片尺寸不能大于${maxSize! / unitMB}MB`,
				});
			}
		});
		if (uploadFiles.length) {
			Promise.all(
				uploadFiles.map((file) => API.feilianyun.pc_upload(file))
			).then((res) => {
				const fileData: FileData[] | null =
					files?.map((file, index) => ({
						fileName: file.name,
						fileSize: file.size,
						filePath: res[index],
					})) || null;
				const currentFileList = multiple
					? [...fileList, ...fileData!]
					: [...fileData!];
				setFileList(currentFileList);

				props.onChange?.(currentFileList);
				inputRef.current.value = null;
			});
		}
	};

	const handleDeleteImg = useCallback(
		(index: number) => {
			fileList.splice(index, 1);
			props.onChange?.(fileList);
			setFileList((prevState) => [...prevState]);
		},
		[fileList]
	);

	return (
		<div className={styles.uploadImgWrapper}>
			{!!fileList.length && (
				<div className={styles.uploadImgList}>
					{fileList.map((file, index) => (
						<div
							key={`${file.filePath}_${index}`}
							className={styles.uploadImg}
							style={{
								backgroundImage: `url(${file.filePath})`,
								...style,
							}}
						>
							<DeleteForeverIcon
								className={styles.deleteIcon}
								onClick={() => handleDeleteImg(index)}
							/>
						</div>
					))}
				</div>
			)}
			<Button className={styles.upload} component="label" style={style}>
				<input
					className={styles.uploadInput}
					type="file"
					hidden
					accept={acceptType}
					multiple={multiple}
					onChange={handleUploadFile}
					ref={inputRef}
				/>
				<AddPhotoAlternateIcon />
			</Button>
		</div>
	);
}
Upload.defaultProps = {
	maxSize: 2 * 1024 * 1024,
};

import { Grid, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import styles from "./footerBar.module.scss";

import { COMMON_URL, QrCODE } from "@/constants/config";

export default function FooterBar() {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("lg"));

	return (
		<div className={styles.footerBar}>
			<div className={styles.footerInner}>
				<Grid container spacing={0} className={styles.footerTop}>
					<Grid item sm={12} md={12} lg={4} className={styles.left}>
						<div>官方QQ群：274220622</div>
						<div>官方邮箱：fly@feilianyun.cn</div>
					</Grid>
					<Grid
						item
						container
						sm={12}
						md={12}
						lg={8}
						className={classNames(styles.right, {
							[styles.rightSmall]: matches,
						})}
						gap={6}
					>
						<div className={styles.rightItem}>
							<img src={QrCODE.wechat} alt="code" />
							<div className={styles.title}>飞链云官方AI绘画微信群</div>
						</div>
						<div className={styles.rightItem}>
							<img src={QrCODE.customer} alt="code" />
							<div className={styles.title}>飞链云官方客服</div>
						</div>

						<div className={styles.rightItem}>
							<img src={QrCODE.officialAccount} alt="code" />
							<div className={styles.title}>微信官方公众号</div>
						</div>

						<div className={styles.rightItem}>
							<img src={QrCODE.miniProgram} alt="code" />
							<div className={styles.title}>飞链云官方小程序</div>
						</div>
					</Grid>
				</Grid>
				<Stack
					className={styles.footerBottom}
					direction="row"
					alignContent="center"
					spacing={2}
				>
					<NavLink to={COMMON_URL.ICP} target="_blank">
						浙ICP备2022026582号
					</NavLink>
					<NavLink
						to={COMMON_URL.publicSecurityFiling}
						target="_blank"
					>
						公安备案号：33010802012749
					</NavLink>
				</Stack>
			</div>
		</div>
	);
}

import { instance } from '@/api/instance';
import {
  LoginParams,
  UserInfoData,
  UserUpdate,
  WxBindPhoneData,
  WxLoginData, WxLoginSuccessData
} from "./type";

// 发送短信验证码
export function sendVerifyCode(phone?: string) {
    return instance<string>({
        method: 'GET',
        url: `/user/login/verification/${phone}`,
    });
}

// 短信验证码登录
export function loginVerifyCode(opt: LoginParams) {
  return instance<string>({
    method: 'POST',
    url: `/user/login/code`,
    data: opt
  });
}

// 密码登录
export function loginPassword(opt: LoginParams) {
  return instance<string>({
    method: 'POST',
    url: `/user/login/passwd`,
    data: opt
  });
}


// 获取用户信息
export function getUserInfo() {
  return instance<UserInfoData>({
    method: 'GET',
    url: '/user/info',
  });
}


// 退出登录
export function logOut() {
  return instance({
    method: 'GET',
    url: '/user/logout',
  });
}

/**
 * 更新用户信息
 * @param params
 * @returns
 */
export function userUpdate(data:UserUpdate) {
  return instance<UserUpdate>({
    method: 'POST',
    url: '/user/update',
    data
  });
}


//用户注销登陆
export function userLogout() {
  return instance({
    method: 'GET',
    url: '/user/logout',
  });
}

/** 微信扫码登录 */
export function wxQrLogin(params: WxLoginData) {
    return instance<WxLoginSuccessData>({
        method: 'GET',
        url: '/wx/callback',
        params
    })
}

/** 微信绑定手机号 */
export function wxUserBindPhone(params: WxBindPhoneData) {
    return instance({
        method: 'POST',
        url: '/user/bind/phone',
        data: params
    })
}

//上传oss空间
export function getStsTemporaryToken() {
  return instance<string>({
    method: 'GET',
    url: '/user/getStsTemporaryToken'
  });
}

import Root from "@/pages/root/root";
import { lazy } from "react";

const PersonalCenter = lazy(() => import("@/pages/personalCenter"));


const PersonalCenterRouter = {
	path: "/personalCenter",
	element: <Root />,
	children: [
		{
			path: "/personalCenter/:id?",
			element: <PersonalCenter />,
			needAuth: true,
			title:"个人中心-飞链云版图"
		}
	],
}

export default PersonalCenterRouter;

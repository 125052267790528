import "./App.css";

import Router from "@/router/index";
import { defaultTheme } from "@/theme";
import { consumeWxLoginCode } from "@/utils/wxlogin";
import { ThemeProvider } from "@mui/material/styles";
import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { pageInitIfUserUnLoginService } from "./service/user";

function App() {
    useEffect(() => {
        consumeWxLoginCode().then(() => {
            pageInitIfUserUnLoginService()
        })
    }, [])

    return (
        <ThemeProvider theme={defaultTheme}>
            <BrowserRouter>
                {Router}
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
